export const PartnerUrl = {
  Home: (partnerId: number) => `/partner/${partnerId}/campaigns`,
  Profile: (partnerId: number) => `/partner/${partnerId}/edit`,
  ReferABrand: (partnerId: number) => `/partner/${partnerId}/refer-a-brand`,
  Payments: (partnerId: number) => `/partner/${partnerId}/payments`,
  Dashboard: (partnerId: number) => `/partner/${partnerId}/dashboard`,
  CampaignHome: (partnerId: number, campaignId: number) =>
    `/partner/${partnerId}/campaigns/${campaignId}`,
  CampaignContentCalendar: (partnerId: number, campaignId: number) =>
    `${PartnerUrl.CampaignHome(partnerId, campaignId)}/content/calendar`,
};
